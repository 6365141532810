import React from "react";
import styles from "./styles.module.scss";
import { linkConfig } from "../../config";
export default () => {
  return (
    <footer className={`${styles.footer} text-center`}>
      <p>
        <a href={linkConfig.risk} target="_blank" rel="noopener noreferrer">
          风险披露
        </a>
        <a href={linkConfig.disclaimer} target="_blank" rel="noopener noreferrer">
          免责声明
        </a>
      </p>
      <p>
        如有任何疑问，欢迎随时联系
        <a href={linkConfig.online} target="_blank" rel="noopener noreferrer">
          在线客服
        </a>
      </p>
      <p>Copyright © {new Date().getFullYear()} 金兴金业版权所有，保留所有权利，不得转载</p>
    </footer>
  );
};
